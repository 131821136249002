import React from 'react'
import {
  isDateAfterToday,
  isDateBeforeToday,
  stripOfficial,
} from 'src/commonFunctions'
import { intl } from 'src/constants'
import ListCard from 'components/molecules/ListCard'
import LinkToIndex from 'components/atoms/LinkToIndex'
import Banner from 'components/atoms/Banner'
import ShareCard from 'components/ShareCard'
import Tags from 'components/atoms/Tags'
import Ogp from 'components/atoms/Ogp'

export default ({
  type,
  linktoindex,
  sharePath,
  articleBaseInfo,
  recommendArticles,
  adbanners,
  children,
}) => {
  const adbanner = adbanners.nodes[0].adbanner
  const displayBanners = adbanner.banners.filter(
    banner => isDateAfterToday(banner.start) && isDateBeforeToday(banner.end)
  )
  const currentBanner = displayBanners[0]
  const {
    nextArticle,
    sameTypeLatestArticles,
    latestArticles,
  } = recommendArticles
  return (
    <div className="article_layout" onClick={e => e.stopPropagation()}>
      <Ogp
        title={articleBaseInfo.title}
        postUrl={articleBaseInfo.slug}
        ogp={articleBaseInfo.ogpSettings.ogp}
      />
      {children}
      {linktoindex ? <LinkToIndex link={linktoindex} /> : <div />}
      <ShareCard
        type={type}
        slug={articleBaseInfo.slug}
        title={articleBaseInfo.title}
        date={articleBaseInfo.date}
        tags={articleBaseInfo.tags}
        isOfficial={articleBaseInfo.isOfficial}
        linkToIndex={linktoindex}
        sharePath={sharePath}
      />
      {articleBaseInfo.tags && <Tags tags={articleBaseInfo.tags} />}
      {nextArticle && (
        <>
          <h3>{intl.getMessage('recommend_article')}</h3>
          <div className="nextArticle">
            <ListCard
              key={nextArticle.id}
              type={nextArticle.type}
              slug={nextArticle.slug}
              title={nextArticle.title}
              date={nextArticle.date}
              isOfficial={stripOfficial(nextArticle.isOfficial)}
              ogp={nextArticle.ogpSettings.ogp}
              linkToIndex={nextArticle.linkToIndex}
              isFull
            />
          </div>
        </>
      )}
      {sameTypeLatestArticles && sameTypeLatestArticles.length > 0 && (
        <div style={{ marginTop: '10px' }}>
          <h3>{intl.getMessage('same_type_latest_articles')}</h3>
          <div className="sameTypeLatestArticles">
            {sameTypeLatestArticles.map(sameTypeLatestArticle => (
              <div className="sameTypeLatestArticle">
                <ListCard
                  key={sameTypeLatestArticle.id}
                  type={sameTypeLatestArticle.type}
                  slug={sameTypeLatestArticle.slug}
                  title={sameTypeLatestArticle.title}
                  date={sameTypeLatestArticle.date}
                  isOfficial={stripOfficial(sameTypeLatestArticle.isOfficial)}
                  ogp={sameTypeLatestArticle.ogpSettings.ogp}
                  linkToIndex={sameTypeLatestArticle.linkToIndex}
                  isFull
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {latestArticles && latestArticles.length > 0 && (
        <div style={{ marginTop: '10px' }}>
          <h3>{intl.getMessage('latest_article')}</h3>
          <div className="latestArticles">
            {latestArticles.map(latestArticle => (
              <div className="latestArticle">
                <ListCard
                  key={latestArticle.id}
                  type={latestArticle.type}
                  slug={latestArticle.slug}
                  title={latestArticle.title}
                  date={latestArticle.date}
                  isOfficial={stripOfficial(latestArticle.isOfficial)}
                  ogp={latestArticle.ogpSettings.ogp}
                  linkToIndex={latestArticle.linkToIndex}
                  isFull
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {currentBanner && (
        <Banner
          name={currentBanner.name}
          link={currentBanner.link}
          image={currentBanner.image}
        />
      )}
    </div>
  )
}
