import React from 'react'
import { Link } from 'gatsby'
import iconMenuTag from 'img/icon_menu_tag.png'
import { intl } from 'src/constants'
import { REPORT_TAGS, COLUMN_TAGS } from 'src/statics'

const Tags = ({ tags }) => {
  const filterdTags = tags.nodes.filter(
    tag => !COLUMN_TAGS.includes(tag.slug) && !REPORT_TAGS.includes(tag.slug)
  )
  return (
    <div className="tags_component">
      <div className="basic_info">
        <img lazyload src={iconMenuTag} className="icon" />
        <p className="subject">{intl.getMessage('tag')}</p>
      </div>
      <div className="tag_list">
        {filterdTags.map(tag => (
          <Link
            key={tag.id}
            className="tagName"
            to={`/tags/${tag.slug.replace(/#/g, 'hash')}/`}
          >
            {`#${tag.name}`}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Tags
