import React from 'react'
import { BASE_URL } from 'src/constants'
import Modal from 'react-modal'

const PROD_WP_BASE_URL = 'https://edit.touhougarakuta.com/'
const STG_WP_BASE_URL = 'https://stg-edit.touhougarakuta.com/'

const SP_WIDTH = 480

export default class InnerHtml extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      imgUrl: '',
      modalIsOpen: false,
    }
  }

  imgUrlset = e => {
    this.setState({
      imgUrl: e.target.currentSrc,
      modalIsOpen: !this.state.modalIsOpen,
    })
  }

  componentDidMount() {
    const root = this.myRef.current
    if (root) {
      const tableTags = Array.from(root.getElementsByTagName('table'))
      tableTags.forEach(tableTags => {
        tableTags.outerHTML = `<div class="table-wrap">${tableTags.outerHTML}</div>`
      })

      const imgTags = Array.from(root.getElementsByTagName('img'))
      imgTags.forEach(img => {
        img.onclick = e => this.imgUrlset(e)
      })

      const aTags = Array.from(root.getElementsByTagName('a'))
      aTags.forEach(aTag => {
        const { href } = aTag

        if (href.indexOf(PROD_WP_BASE_URL) >= 0) {
          aTag.href = href.replace(PROD_WP_BASE_URL, BASE_URL)
        } else if (href.indexOf(STG_WP_BASE_URL) >= 0) {
          aTag.href = href.replace(STG_WP_BASE_URL, BASE_URL)
        }

        if (href.indexOf('#') === 0) {
          aTag.setAttribute('target', '_blank')
        }
        aTag.setAttribute('rel', 'noopener')
      })
    }
  }

  render() {
    return (
      <>
        <div
          ref={this.myRef}
          className={this.props.className}
          dangerouslySetInnerHTML={{ __html: this.props.html }}
        />
        <Modal
          isOpen={
            typeof window !== 'undefined'
              ? window.innerWidth > SP_WIDTH && this.state.modalIsOpen
              : this.state.modalIsOpen
          }
          onRequestClose={() =>
            this.setState({ modalIsOpen: !this.state.modalIsOpen })
          }
          className="modalSearchWindow"
          overlayClassName="modalSearchOverlay lightbox"
        >
          <img lazyload src={this.state.imgUrl} />
        </Modal>
      </>
    )
  }
}
