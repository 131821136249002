import React from 'react'
import uuid from 'uuid/v4'
import { Query } from 'react-apollo'
import { getCreditsQuery } from 'src/queries'
import share_icon_twitter from 'img/share_icon_twitter.svg'
import share_icon_web from 'img/share_icon_web.svg'
import share_icon_youtube from 'img/share_icon_youtube.svg'
import { intl } from 'src/constants'

export default class Credits extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Query
        query={getCreditsQuery(this.props.slug, this.props.type)}
        context={this.props.context}
      >
        {({ data }) => {
          const credits = data[this.props.type]
            ? data[this.props.type].credits.credits
            : null
          return (
            <div className="credits">
              {credits && credits.length > 0 ? (
                credits.map(({ credit }) => this.renderCredit(credit))
              ) : (
                <div />
              )}
            </div>
          )
        }}
      </Query>
    )
  }

  renderCredit({ role, user }) {
    const existCredit = !!role && !!user
    const { profile } = user || {}
    const { termSlugs } = user || []
    return existCredit ? (
      <div key={uuid()} className="credit">
        <p className="role">{role}</p>
        <div className="flex">
          <div className="left">
            <img lazyload src={profile.image.sourceUrl}></img>
          </div>
          <div className="right">
            <h4>
              {termSlugs && termSlugs.length > 0 ? (
                <a href={`/tags/${termSlugs[0]}`}>{user.title}</a>
              ) : (
                <>{user.title}</>
              )}
            </h4>
            <div className="profile">{profile.texts}</div>
            <div className="links">{this.renderLink(profile.links)}</div>
          </div>
        </div>
      </div>
    ) : (
      <div key={uuid()} />
    )
  }

  renderLink(links) {
    const items = [
      this.renderLinkContent(links.url, share_icon_web, 'link_web'),
      this.renderLinkContent(links.twitter, share_icon_twitter, 'link_twitter'),
      this.renderLinkContent(links.youtube, share_icon_youtube, 'link_youtube'),
    ].filter(item => item)
    return items.length > 0 ? <ul>{items}</ul> : undefined
  }

  renderLinkContent(url, src, msgKey) {
    return !!url ? (
      <li key={uuid()}>
        <a href={url} target="_blank">
          <img lazyload src={src}></img>
          <span>{intl.getMessage(msgKey)}</span>
        </a>
      </li>
    ) : (
      undefined
    )
  }
}
